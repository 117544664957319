import React, { useState, useEffect } from "react";
import './styles/css/tailwind.css';
import './styles/scss/tailwind.scss';

import './styles/scss/icons.scss';

import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";

import HomeScreen from "./screen/homeScreen";
import Loader from "./components/landingComponent/Loader";


function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.documentElement.setAttribute('dir', 'ltr');
    handleRouteChange();
  }, []);


  // Route change method
  const handleRouteChange = () => {
    setLoading(false);
  }
  return (
    <BrowserRouter>
      {loading && <Loader />}
      <Routes>
        <Route path="/" element={<HomeScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
