import React from "react";
import { Link } from "react-router-dom";

import StrategyImage from "../../assets/images/svg/design-thinking.svg";
import departmentImage from "../../assets/images/svg/coding.svg";
import launchImage from "../../assets/images/svg/office-desk.svg";

export default function Services() {
  const team = [
    {
      title: 'Grow Your Business',
      icon: 'adjust-circle',
      subtext: 'Expand your reach and increase your revenue with our expert strategies and solutions.'
    },
    {
      title: 'Drive More Sales',
      icon: 'circuit',
      subtext: 'Boost your sales with our innovative marketing strategies and expert guidance.'
    },
    {
      title: 'Handled By Expert',
      icon: 'fire',
      subtext: 'Our expert team ensures your business runs smoothly and efficiently.'
    },
    {
      title: 'Discussion For Idea',
      icon: 'shopping-basket',
      subtext: 'Engage in meaningful discussions to refine your ideas and align them with our expertise.'
    },
    {
      title: 'Increase Conversion',
      icon: 'flower',
      subtext: 'Optimize your conversion rates with our proven strategies and expert guidance.'
    },
    {
      title: 'Sales Growth Idea',
      icon: 'flip-h',
      subtext: 'Leverage our sales growth ideas to boost your revenue and achieve your business goals.'
    }
  ]

  return (
    <>
      {/* Start */}
      <section className="relative md:py-24 py-16 active" id="features">
        <div className="container lg mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 pb-8 items-center">
            <div>
              <h6 className="text-green-600 text-base font-medium uppercase mb-2">What We Do ?</h6>
              <h3 className="mb-4 md:text-2xl text-xl font-semibold dark:text-white md:mb-0">Perfect Solution For Your <br /> Business</h3>
            </div>

            <div>
              <p className="text-slate-400 dark:text-slate-300 max-w-xl">Our perfect solution for your business is designed to address your unique challenges and drive growth. We combine innovative strategies with expert guidance to help you achieve your goals and succeed in a competitive market.</p>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">

            {team.map((item, key) => (
              <div key={key} className={`features p-6 ${key % 2 === 0 ? "hover:shadow-xl hover:shadow-slate-100 dark:hover:shadow-slate-800" : "shadow-xl shadow-slate-100 dark:shadow-slate-800"} transition duration-500 rounded-3xl mt-8`}>
                <div className="w-20 h-20 bg-green-600/5 text-green-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm">
                  <i className={`uil uil-${item.icon}`}></i>
                </div>

                <div className="content mt-7">
                  <Link to="#" className="text-lg hover:text-green-600 dark:text-white dark:hover:text-green-600 transition-all duration-500 ease-in-out font-medium">{item.title}</Link>
                  <p className="text-slate-400 mt-3">{item.subtext}</p>

                  <div className="mt-5">
                    <Link to="#" className="btn btn-link hover:text-green-600 dark:hover:text-green-600 after:bg-green-600 dark:text-white transition duration-500">Read More <i className="uil uil-arrow-right"></i></Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="container md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-green-600 text-base font-medium uppercase mb-2">Work Process</h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Digital System For Our Business</h3>

            <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Explore our digital system and leverage our expertise in designing and managing conversion-centered Tailwind CSS HTML pages for your business growth.</p>
          </div>

          <div className="grid grid-cols-1 mt-8">
            <div className="timeline relative">
              <div className="timeline-item">
                <div className="grid sm:grid-cols-2">
                  <div className="">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                      <img src={StrategyImage} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                  <div className="mt-4 md:mt-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">Strategy</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">Our strategy involves a comprehensive approach to understanding your business needs and delivering tailored solutions. We focus on innovation, efficiency, and growth to ensure your success.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="md:order-1 order-2">
                    <div className="event event-description-left ltr:float-left rtl:float-right ltr:text-right rtl:text-left md:me-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">Development Business</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">Our development process is designed to be efficient and effective, ensuring that your business is always running smoothly and efficiently.</p>
                    </div>
                  </div>
                  <div className="md:order-2 order-1">
                    <div className="duration duration-right md:ms-7 relative">
                      <img src={departmentImage} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="mt-4 mt-sm-0">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                      <img src={launchImage} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                  <div className="mt-4 mt-sm-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">Own Branch Office Launch</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">Our launch process is designed to be seamless and effective, ensuring that your business is ready to take off and achieve its goals.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );

}
